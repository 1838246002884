<template>
  <div class="test-plan-duplicate font-inter py-2" fluid>
    <v-card class="test-plans-section-header white pt-4 px-6 pb-4 mb-2" rounded="lg" elevation="0" width="100%">
      <div>
        <button plain class="btn-nav-back font-inter" @click="handleBackClick">
          <v-icon color="blue">mdi-chevron-left</v-icon>
          <span class="d-flex-inline justify-center align-center ma-0 blue--text">
            {{ $t('plans.create.backToCreatePlans') }}
          </span>
        </button>
      </div>
      <h2 class="mt-4">{{ $t('plans.duplicate.title') }}</h2>
      <div class="mt-4 d-flex">
        <v-chip
          class="font-inter font-weight-bold px-4"
          width="200px"
          label
          :class="{ 'blue--text': tab === 'ongoing' }"
          :color="tab === 'ongoing' ? 'blue-light' : 'gray-light'"
          @click="$emit('tab', 'ongoing')"
        >
          {{ $t('ongoing') }} <span class="ml-2">{{ ongoingCount }}</span>
        </v-chip>
        <v-chip
          class="font-inter font-weight-bold px-4 ml-2"
          label
          width="200px"
          :class="{ 'blue--text': tab === 'archived' }"
          :color="tab === 'archived' ? 'blue-light' : 'gray-light'"
          @click="$emit('tab', 'archived')"
        >
          {{ $t('archived') }} <span class="ml-2">{{ archivedCount }}</span>
        </v-chip>
      </div>
      <slot name="additional-actions" />
    </v-card>
    <v-card class="test-plans-duplicate-wrapper pt-6 px-6" v-if="testPlans.length <= 0">
      <placeholder />
    </v-card>
    <v-card class="test-plans-duplicate-wrapper pt-6 px-6" rounded="lg" elevation="0" v-else>
      <div class="search-container d-flex">
        <search-field class="search-input mr-2" v-model="searchFilter" :placeholder="$t('searchByName')" />
        <v-btn
          class="d-flex align-center justify-center text-none px-0"
          height="40"
          color="gray-100"
          depressed
          @click="isOpenFilter = true"
        >
          <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
        </v-btn>
        <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
          <template v-slot:activator="{ on }">
            <v-btn class="ml-auto pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
              <v-icon size="16px"> mdi-cog-outline </v-icon>
            </v-btn>
          </template>
          <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
            <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{ $t('columns') }}</v-list-item>
            <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item" :key="index">
              <v-checkbox
                v-model="header.isSelected"
                :value="header.isSelected"
                :true-value="true"
                :false-value="false"
                off-icon="mdi-checkbox-blank"
                class="ma-0 pa-0"
                color="blue"
                :size="16"
                hide-details
              >
                <template v-slot:label>
                  <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}</div>
                </template>
              </v-checkbox>
            </v-list-item>
            <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
              <div
                class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                :class="{ 'blue--text font-weight-semibold ': isColumnFilter }"
              >
                {{ $t('Restore default') }}
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <plans-list
        class="plans-list"
        :data="testPlans"
        :tab="tab"
        :headers="listHeaders"
        @click:item="handleClickItem"
      />
      <div class="actions-container d-flex justify-end py-4 pr-6">
        <v-btn
          class="font-inter font-weight-bold text-none"
          dark
          depressed
          color="blue"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
          @click="handleDuplicate"
        >
          {{ $t('duplicate') }}
        </v-btn>
      </div>
    </v-card>
    <plans-list-filter :open="isOpenFilter" @filters="applyFilters" @close="isOpenFilter = false" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import Placeholder from '@/views/Tests/Plans/Components/Placeholder';
import SearchField from '@/components/Form/SearchField';
import PlansListFilter from '@/views/Tests/Plans/Components/List/PlansListFilter';
import PlansList from '@/views/Tests/Plans/Components/List/PlansList';

import { testPlans } from '@/constants/data.js';
import { TestPlansTableHeader } from '@/constants/grid.js';

export default {
  components: {
    Placeholder,
    SearchField,
    PlansListFilter,
    PlansList,
  },
  data() {
    return {
      tab: 'ongoing',
      testPlans,
      isOpenFilter: false,
      headers: TestPlansTableHeader,
      isColumnFilter: false,
    };
  },
  computed: {
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
    listHeaders() {
      const filtered = this.headers.filter((x) => x.isSelected);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
  },
  methods: {
    handleTab(value) {
      this.tab = value;
    },
    handleBackClick() {
      this.$router.replace({ name: 'TestPlanCreate' });
    },
    handleColumnReset() {
      this.headers = this.headers.map((header) => {
        header.isSelected = true;
        return header;
      })
    },
    handleClickItem(item) {
      this.$router.push({ name: 'TestPlanRerun', params: { handle: this.$route.params.handle, key: this.$route.params.key, planId: item.id } });
    },
    handleDuplicate() {
      this.$router.push({ name: 'TestPlanDuplicateAddRuns', params: { handle: this.$route.params.handle, key: this.$route.params.key } });
    },
  },
};
</script>

<style lang="scss">
.test-plan-duplicate {
  height: calc(100vh - 90px - 8px);
  background: #f2f4f7;

  display: flex;
  flex-direction: column;
}

.test-plans-section-header {
  flex: 0 1 138px;
}

.test-plans-duplicate-wrapper {
  flex: 1 1 auto;
  position: relative;
  padding-bottom: 68px;

  display: flex;
  flex-direction: column;
}

.search-container {
  flex: 0 1 40px;
}

.plans-list {
  flex: 1 1 auto;
  overflow: auto;
}

.btn-nav-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3 !important;
  text-transform: none;
  opacity: 1;

  display: flex;
  align-items: center;
}

.actions-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
